<template>
  <div
    id="webviewer"
    ref="viewer"
    contextmenu.stop
  />
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import WebViewer from '@pdftron/webviewer'

export default {
  name: 'WebViewer',
  props: {
    initialDoc: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const viewer = ref(null)
    onMounted(() => {
      const path = `${process.env.BASE_URL}webviewer`
      console.log(document.querySelector('DocumentContainer'))
      WebViewer({ path, initialDoc: props.initialDoc }, viewer.value).then(
        instance => {
          // const { documentViewer, } = instance.Core;

          // documentViewer.addEventListener("documentLoaded", () => {
          // const rectangleAnnot = new Annotations.RectangleAnnotation({
          //   PageNumber: 1,
          //   // values are in page coordinates with (0, 0) in the top left
          //   X: 100,
          //   Y: 150,
          //   Width: 200,
          //   Height: 50,
          //   Author: annotationManager.getCurrentUser(),
          // });
          // annotationManager.addAnnotation(rectangleAnnot);
          // annotationManager.redrawAnnotation(rectangleAnnot);
          instance.UI.disableElements(['ribbons'])
          instance.UI.disableElements(['textPopup'])
          // instance.UI.disableElements(['contextMenuPopup'])
          instance.UI.disableElements(['freeTextToolButton'])
          instance.UI.disableElements(['freeHandHighlightToolButton'])
          instance.UI.disableElements(['freeHandToolButton'])
          instance.UI.disableElements(['highlightToolButton'])
          instance.UI.disableElements(['stickyToolButton'])

          instance.UI.disableElements(['toolsHeader'])
          instance.UI.disableElements(['downloadButton'])
          instance.UI.disableElements(['printButton'])
          instance.UI.disableElements(['languageButton'])
          // instance.UI.disableElements(['menuButton'])

          // });
        },
      )
    })
    return {
      viewer,
    }
  },
}
</script>

<style>
#webviewer {
  height: 70vh;
  width: 100%!important;
}
</style>
