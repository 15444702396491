<template>
  <div class="w-100 h-100 position-relative">
    <!-- style="min-height: 400px;" -->
    <div
      v-if="loader"
      class="
        position-absolute
        bv
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <div>
        <b-spinner
          style="width: 5rem; height: 5rem"
          variant="secondry"
        />
        <h4 class="mt-1 text-secondry">
          Loading...
        </h4>
      </div>
    </div>
    <div
      v-if="docType1 || docType2"
    >
      <!-- <iframe
        v-if="docType1"
        class="w-100 h-100 position-absolute"
        :src="url + '#toolbar=0&navpanes=0&scrollbar=0'"
        frameborder="0"
      /> -->
      <!-- <iframe
        v-if="docType2"
        id="frame_doc"
        ref="aaaa"
        class="position-absolute"
        :src="`https://view.officeapps.live.com/op/view.aspx?src=${url}`"
        width="100%"
        height="100%"
        frameborder="0"
      >This is an embedded
        <a target="_blank" href="http://office.com">Microsoft Office</a>
        document, powered by
        <a target="_blank" href="http://office.com/webapps">Office Online</a
        >.</iframe> -->
      <!-- <iframe
        v-if="docType2"
        class="position-absolute"
        :src="`https://docs.google.com/gview?url=${url}&embedded=true`"
        width="100%"
        height="100%"
        frameborder="0"
      /> -->
      <WebViewer
        :initial-doc="url"
      />
    </div>
    <div
      v-if="img"
      class="
        w-100
        h-100
        position-absolute
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <b-img
        class="text-center w-100 h-100"
        style="object-fit: contain;"
        thumbnail
        fluid
        :src="url"
        alt="Image 1"
      />
    </div>
    <div
      v-else-if="audio"
      class="w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <vue-plyr
          ref="player"
          :options="options"
        >
          <audio
            controls
            crossorigin
            playsinline
            :options="options"
          >
            <source
              :src="url"
              :options="options"
              :type="`audio/${defoult}`"
            >
            Your browser does not support the audio element.
          </audio>
        </vue-plyr>
      </div>
    </div>
    <div
      v-else-if="video"
      class="w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <vue-plyr class="m-auto">
        <video
          id="my-video"
          class="w-100 h-100"
          controls
          preload="auto"
          width="500"
          height="500"
          data-setup="{}"
        >
          <source
            :src="url"
            :type="`video/${defoult}`"
          >
        </video>
      </vue-plyr>
    </div>
    <div v-else-if="embed">
      <vue-plyr>
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="url"
          allowfullscreen
        />
      </vue-plyr>
    </div>
    <div
      v-if="noFormat"
      class="w-100 d-flex align-items-center justify-content-center"
    >
      <h3>Такой формат не поддерживается!</h3>
    </div>
  </div>
</template>

<script>
import { BSpinner, BImg, BEmbed } from 'bootstrap-vue'
import VuePlyr from 'vue-plyr'
import WebViewer from './WebViewer.vue'
import 'vue-plyr/dist/vue-plyr.css'

export default {
  components: {
    VuePlyr,
    BSpinner,
    BImg,
    BEmbed,
    WebViewer,
  },
  props: {
    src: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      loader: false,
      docLoader: false,
      video: false,
      docType1: false,
      docType2: false,
      img: false,
      audio: false,
      noFormat: false,
      embed: false,
      options: {
        seekTime: 2,
      },
      docFormats1: ['pdf'],
      docFormats2: ['pptx', 'xls', 'ppt', 'docx', 'doc'],
      imgFormats: ['bmp', 'jpg', 'jpeg', 'gif', 'png', 'jfif'],
      audioFormats: ['mp3', 'wav', 'ogg'],
      videoFormats: ['webm', 'mp4'],
      defoult: '',
    }
  },
  watch: {
    src(val) {
      this.setNull()
      if (val) {
        this.url = val
        let format = val.split('.')
        format = format[format.length - 1].toLowerCase()
        // if (this.url.search('www.youtube.com') !== -1) {
        if (/www.youtube.com/gm.test(this.url)) {
          this.$nextTick(() => {
            this.embed = true
          })
        } else {
          this.$nextTick(() => {
            this.check(format)
          })
        }
      }
    },
  },
  mounted() {
    this.setNull()
    this.loader = true
    if (this.src) {
      this.url = this.src
      let format = this.src.split('.')
      format = format[format.length - 1].toLowerCase()
      if (this.url.search('www.youtube.com') !== -1) {
        // if (/www.youtube.com/gm.test(this.url)) {
        const link = this.url.split('=')
        const youTube = `https://www.youtube.com/embed/${link[1]}`
        this.url = youTube
        this.$nextTick(() => {
          this.embed = true
        })
      } else {
        this.$nextTick(() => {
          this.check(format)
        })
      }
    }
  },
  destroyed() {
    this.setNull()
  },
  methods: {
    check(format) {
      if (this.docFormats1.includes(format)) {
        this.docType1 = true
      } else if (this.docFormats2.includes(format)) {
        this.docType2 = true
      } else if (this.imgFormats.includes(format)) {
        this.img = true
      } else if (this.audioFormats.includes(format)) {
        this.defoult = format
        this.audio = true
      } else if (this.videoFormats.includes(format)) {
        this.defoult = format
        this.video = true
      } else {
        this.$nextTick(() => {
          this.noFormat = true
        })
      }
      this.$nextTick(() => {
        this.loader = false
      })
    },
    setNull() {
      this.url = null
      this.docType1 = false
      this.docType2 = false
      this.img = false
      this.video = false
      this.audio = false
      this.noFormat = false
      this.embed = false
    },
  },
}
</script>

<style>
.bv {
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
/*
      style="overflow: scroll; cursor: grab; cursor : -o-grab; cursor : -moz-grab; cursor : -webkit-grab;" */
</style>
