<template>
  <!-- class="unselectable" -->
  <div
    ref="mainContainer"
    style="box-sizing: border-box; display: flex"
    @mousemove="resize"
    @mouseup="resizing = false"
    @mouseleave="resizing = false"
  >
    <div
      ref="leftSide"
      :style="getLeftWidth"
    >
      <slot name="left-side" />
    </div>
    <span
      id="test"
      ref="dragbar"
      style="padding 6px; cursor: col-resize; background-color: silver; width: 10px; z-index: 10"
      @mousedown="resizing = true"
      @click="resize"
    />
    <div
      ref="rightSide"
      style="width: 60%; flex: 1"
    >
      <slot name="right-side" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    ratio: {
      type: String,
      required: false,
      default: '1:1',
    },
  },
  data() {
    return {
      resizing: false,
    }
  },
  computed: {
    getLeftWidth() {
      if (this.$refs.mainContainer) {
        return `width: ${this.$refs.mainContainer.clientWidth / 2}`
      }
      return 'width: 50%'
    },
  },
  // mounted() {
  //   document.getElementById('test').addEventListener('touchend', e => {
  //     this.resizing = true
  //     alert(JSON.stringify(e))
  //     // alert('test1')
  //   })
  //   document.getElementById('test').addEventListener('touchstart', e => {
  //     this.resizing = true
  //     alert(JSON.stringify(e))
  //     // alert('test')
  //   })
  //   document.getElementById('test').addEventListener('touchmove', e => {
  //     e.preventDefault()
  //     // if (this.resizing) {
  //     //   console.log(e)
  //     //   this.$refs.leftSide.style.width = `${this.$refs.leftSide.clientWidth + e.movementX}px`
  //     // }
  //     alert(JSON.stringify(e))
  //   }, false)
  // },
  methods: {
    resize(e) {
      if (this.resizing) {
        console.log(e)
        this.$refs.leftSide.style.width = `${this.$refs.leftSide.clientWidth + e.movementX}px`
      }
    },
  },
}
</script>

<style lang="scss">
  .unselectable {
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;

   /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
   -ms-user-select: none;
   user-select: none;
}
</style>
